import http from "../common-http";

import fileAPI from "../common-http";

class HRMService {
  getAll() {
    return http.get("/hrm/employee/");
  }

  getOrders() {
    return http.get("/sales/get-sale-order/0");
  }

  getOrderItems(id: number) {
    return http.get(`/sales/get-sale-order/${id}`);
  }

  get_employee(id: string) {
    return http.get(`/hrm/get-employee/${id}`);
  }

  post_employee_file(file: any) {
    let formData = new FormData();
    formData.append('file', file);
    return fileAPI.post(`/hrm/post-file-employee/`, formData);
  }
  
  post_salary_file(file: any) {
    let formData = new FormData();
    formData.append('file', file);
    return fileAPI.post(`/hrm/post-file-employee-salary/`, formData);
  }
  
  post_payroll_file(file: any, data: any) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('year', data.year);
    formData.append('month', data.month);
    return fileAPI.post(`/hrm/post-file-payroll/`, formData);
  }

  
  getproductitem() {
    return http.get("/product/item/");
  }

  getproductStock() {
    return http.get("/product/item-stock/");
  }


  getAll_salary() {
    return http.get("/hrm/get-salary/");
  }

  get_salary(id: string) {
    return http.get(`/hrm/employee_salary/${id}`,);
  }

  get_attendance_sheet() {
    return http.get("/hrm/attendance_sheet/");
  }

  get_attendance_sheet_by(obj: any) {
    return http.post("/hrm/get-attendance-sheet/", obj);
  }

  get_project_by_emp(id: any) {
    return http.get(`/project/get-project-employee/?employee_id=${id}`);
  }

  get_employee_payroll(obj: any) {
    return http.post("/hrm/employee-payroll/", obj);
  }

  post_employee_payroll(obj: any) {
    return http.post("/hrm/employee-payroll-generate/", obj);
  }


  employee_payrollById(id: any) {
    return http.get(`/hrm/employee_payroll/${id}`);
  }

  

  import_attendance(obj: any) {
    return http.post("/hrm/import-attendance/", obj);
  }

  create_attendance(obj: any) {
    return http.post("/hrm/create-attendance/", obj);
  }

  getAll_attendance() {
    return http.get("/hrm/get-attendance/");
  }

  get_attendance(id: string) {
    return http.get(`/hrm/attendance/${id}`);
  }

  get_asset_by_emp(id: any) {
    return http.get(`/asset/get-asset-employee/?employee_id=${id}`);
  }


  getAllassest() {
    return http.get("/asset/list/");
  }

  createassetemp(obj: any) {
    return http.post("/asset/asset-emp/", obj);
  }


  get(id: string) {
    return http.get(`/hrm/employee/${id}`);
  }

  create(data: any) {
    return http.post("/hrm/employee/", data);
  }

  create_basic_emp(data: any) {
    return http.post("/hrm/post-basic-employee/", data);
  }

  update(id: number, data: any) {
    return http.put(`/hrm/employee/${id}/`, data);
  }

  create_salary(data: any) {
    return http.post(`/hrm/employee_salary/`, data);
  }

  update_salary(id: number, data: any) {
    return http.put(`/hrm/employee_salary/${id}/`, data);
  }

  delete(id: string) {
    return http.delete(`/hrm/employee//${id}`);
  }

  deleteAll() {
    return http.delete(`/hrm/employee/`);
  }

  findByTitle(title: string) {
    return http.get(`/hrm/employee/?title=${title}`);
  }

  create_edu(data: any) {
    return http.post(`/hrm/employee_education/`, data);
  }

  update_edu(id: number, data: any) {
    return http.put(`/hrm/employee_education/${id}/`, data);
  }

  get_edu_by_emp(id: string) {
    return http.get(`/hrm/employee_education_by_emp/?employee_id=${id}`);
  }

  create_passport(data: any) {
    return http.post(`/hrm/employee_passport/`, data);
  }

  update_passport(id: number, data: any) {
    return http.put(`/hrm/employee_passport/${id}/`, data);
  }

  get_passport_by_emp(id: string) {
    return http.get(`/hrm/employee_passport_by_emp/?employee_id=${id}`);
  }

  create_iqama(data: any) {
    return http.post(`/hrm/employee_iqama/`, data);
  }

  update_iqama(id: number, data: any) {
    return http.put(`/hrm/employee_iqama/${id}/`, data);
  }

  get_iqama_by_emp(id: string) {
    return http.get(`/hrm/employee_iqama_by_emp/?employee_id=${id}`);
  }

  create_visa(data: any) {
    return http.post(`/hrm/employee_visa/`, data);
  }

  update_visa(id: number, data: any) {
    return http.put(`/hrm/employee_visa/${id}/`, data);
  }

  get_visa_by_emp(id: string) {
    return http.get(`/hrm/employee_visa_by_emp/?employee_id=${id}`);
  }

  create_workpermit(data: any) {
    return http.post(`/hrm/employee_workpermit/`, data);
  }

  update_workpermit(id: number, data: any) {
    return http.put(`/hrm/employee_workpermit/${id}/`, data);
  }

  get_workpermit_by_emp(id: string) {
    return http.get(`/hrm/employee_workpermit_by_emp/?employee_id=${id}`);
  }

  create_drivinglicense(data: any) {
    return http.post(`/hrm/employee_drivinglicense/`, data);
  }

  update_drivinglicense(id: number, data: any) {
    return http.put(`/hrm/employee_drivinglicense/${id}/`, data);
  }

  get_drivinglicense_by_emp(id: string) {
    return http.get(`/hrm/employee_drivinglicense_by_emp/?employee_id=${id}`);
  }

  create_medicalinsurance(data: any) {
    return http.post(`/hrm/employee_medical/`, data);
  }

  update_medicalinsurance(id: number, data: any) {
    return http.put(`/hrm/employee_medical/${id}/`, data);
  }

  get_medicalinsurance_by_emp(id: string) {
    return http.get(`/hrm/employee_medical_by_emp/?employee_id=${id}`);
  }

  create_contract(data: any) {
    return http.post(`/hrm/employee_contract/`, data);
  }

  update_contract(id: number, data: any) {
    return http.put(`/hrm/employee_contract/${id}/`, data);
  }

  get_contract_by_emp(id: string) {
    return http.get(`/hrm/employee_contract_by_emp/?employee_id=${id}`);
  }

  create_aramco(data: any) {
    return http.post(`/hrm/employee_aramco/`, data);
  }

  update_aramco(id: number, data: any) {
    return http.put(`/hrm/employee_aramco/${id}/`, data);
  }

  get_aramco_by_emp(id: string) {
    return http.get(`/hrm/employee_aramco_by_emp/?employee_id=${id}`);
  }

  // create_doc(data: any) {
  //   return http.post(`/hrm/employee_doc/`, data);
  // }

  update_doc(id: number, data: any) {
    return http.put(`/hrm/employee_doc/${id}/`, data);
  }

  get_doc_by_emp(id: string) {
    return http.get(`/hrm/employee_doc_by_emp/?employee_id=${id}`);
  }
  
  create_doc(file: any, data: any) {
    let formData = new FormData();
    formData.append('employee_title',data.title);
    formData.append('employee_code', data.code);
    formData.append('employee', data.employee);
    formData.append('document', file);
    formData.append('name', data.name);
    formData.append('type', data.type);
    formData.append('comments', data.comments);
    return fileAPI.post(`/hrm/employee_doc/`, formData);
  }
   // Work Location
   getAll_project_id() {
    return http.get("/hrm/employee_aramco/"); // change wants to implement
  }
  getAll_worklocation() {
    return http.get("/project/employee-project/"); // change wants to implement
  }
  getAll_iqama(){
    return http.get("/hrm/employee_iqama/");
  }
  getAll_workpermit() {
    return http.get("/hrm/employee_workpermit/");
  }
  getAll_medical(){
    return http.get("/hrm/employee_medical/");
  }
  getAll_documents(){
    return http.get("/hrm/employee_doc/");
  }
  getAll_education(){
    return http.get("/hrm/employee_education/");
  }
  getAll_passports(){
    return http.get("/hrm/employee_passport/");
  }
  getAll_visa(){
    return http.get("/hrm/employee_visa/");
  }
  getAll_drivinglicense(){
    return http.get("/hrm/employee_drivinglicense/");
  }




}

export default new HRMService();