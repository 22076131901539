import http from "../common-http";

class BuiltinService {
  getAll() {
    return http.get("/accounts/chart/");
  }

  getallbuiltin() {return http.get("/builtin/")}

  getasset() {return http.get(`/builtin/asset/`)}
  postasset(data :any) {return http.post(`/builtin/asset/`, data)}
  putasset(id: string, data:any) {return http.put(`/builtin/asset/${id}/`, data)}
  deleteasset(id: string) {return  http.delete(`/builtin/asset/${id}/`)}

  getexpats() {return http.get(`/builtin/expat/`)}
  postexpats(data :any) {return http.post(`/builtin/expat/`, data)}
  putexpats(id: string, data:any) {return http.put(`/builtin/expat/${id}/`, data)}
  deleteexpats(id: string) {return  http.delete(`/builtin/expat/${id}/`)}

  getnationality() {return http.get(`/builtin/nationality/`)}
  postnationality(data :any) {return http.post(`/builtin/nationality/`, data)}
  putnationality(id: string, data:any) {return http.put(`/builtin/nationality/${id}/`, data)}
  deletenationality(id: string) {return  http.delete(`/builtin/nationality/${id}/`)}

  getbranch() {return http.get(`/builtin/branch/`)}
  postbranch(data :any) {return http.post(`/builtin/branch/`, data)}
  putbranch(id: string, data:any) {return http.put(`/builtin/branch/${id}/`, data)}
  deletebranch(id: string) {return  http.delete(`/builtin/branch/${id}/`)}

  getsubbranch() {return http.get(`/builtin/subbranch/`)}
  postsubbranch(data :any) {return http.post(`/builtin/subbranch/`, data)}
  putsubbranch(id: string, data:any) {return http.put(`/builtin/subbranch/${id}/`, data)}
  deletesubbranch(id: string) {return  http.delete(`/builtin/subbranch/${id}/`)}

  getemploymenttype() {return http.get(`/builtin/employmenttype/`)}
  postemploymenttype(data :any) {return http.post(`/builtin/employmenttype/`, data)}
  putemploymenttype(id: string, data:any) {return http.put(`/builtin/employmenttype/${id}/`, data)}
  deleteemploymenttype(id: string) {return  http.delete(`/builtin/employmenttype/${id}/`)}

  getdesignation() {return http.get(`/builtin/designation/`)}
  postdesignation(data :any) {return http.post(`/builtin/designation/`, data)}
  putdesignation(id: string, data:any) {return http.put(`/builtin/designation/${id}/`, data)}
  deletedesignation(id: string) {return  http.delete(`/builtin/designation/${id}/`)}

  getdepartment() {return http.get(`/builtin/department/`)}
  postdepartment(data :any) {return http.post(`/builtin/department/`, data)}
  putdepartment(id: string, data:any) {return http.put(`/builtin/department/${id}/`, data)}
  deletedepartment(id: string) {return  http.delete(`/builtin/department/${id}/`)}

  geteducationdetail() {return http.get(`/builtin/educationdetail/`)}
  posteducationdetail(data :any) {return http.post(`/builtin/educationdetail/`, data)}
  puteducationdetail(id: string, data:any) {return http.put(`/builtin/educationdetail/${id}/`, data)}
  deleteeducationdetail(id: string) {return  http.delete(`/builtin/educationdetail/${id}/`)}

  getbloodgroup() {return http.get(`/builtin/bloodgroup/`)}
  postbloodgroup(data :any) {return http.post(`/builtin/bloodgroup/`, data)}
  putbloodgroup(id: string, data:any) {return http.put(`/builtin/bloodgroup/${id}/`, data)}
  deletebloodgroup(id: string) {return  http.delete(`/builtin/bloodgroup/${id}/`)}

  getproject() {
    return http.get(`/project/list/`); 
  }

  getchartchildren() {
    return http.get(`/accounts/get-children/`); 
  }

  get(id: string) {
    return http.get(`/accounts/chart/${id}`); 
  }

  create(data: any) {
    return http.post("/accounts/chart/", data);
  }

  update(id: string, data: any) {
    return http.put(`/accounts/chart/${id}/`, data);
  }

  delete(id: string) {
    return http.delete(`/accounts/chart/${id}`);
  }

  deleteAll() {
    return http.delete(`/accounts/chart/`);
  }

  findByTitle(title: string) {
    return http.get(`/accounts/chart/?title=${title}`);
  }

  getauth() {
    return http.get("/api/auth-user/");
  }

}


export default new BuiltinService();